@import 'base'
.Navbar
  overflow-y: scroll
  width: 230px
  background: $color-gray
  color: rgba(white, 0.8)
  box-shadow: 1px 0 0 0 #e6e6e6
  transition: color .2s
  a:hover
    color: white

  +phone
    width: 60px
    overflow-x: hidden

  > .user
    cursor: pointer
    padding: 20px 0.5em
    font-size: 15px
    font-weight: 600
    +phone
      padding: 1em 0
      .Avatar
        margin: 0 auto
        display: block
      .name, svg
        display: none

  section
    &:before
      content: attr(title)
      display: block
      padding: 10px 20px
      text-transform: uppercase
      font-size: 0.9em
      font-weight: bold
      color: rgba(white, 0.66)

    ul
      list-style: none
      padding: 0
      li
        a
          display: block
          padding: 8px 25px
          font-weight: 600
          transition: all .2s
          &.active
            background: lighten( $color-gray, 5% )
            color: white
        a + ul
          display: none
          padding: 16px 0
          box-shadow: inset 0 -2px 2px 0 rgba( white, 0.05 )
          li a
            padding-left: 50px
        a.active + ul
          display: block
