@import 'base'
.Spinner
  margin: 4em auto
  text-align: center

  & > div
    margin: 0 0.4em
    width: 1.5em
    height: 1.5em
    background-color: rgba(128,128,128,0.33)

    border-radius: 100%
    display: inline-block
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both
    animation: sk-bouncedelay 1.4s infinite ease-in-out both

  & > :first-child
    -webkit-animation-delay: -0.16s
    animation-delay: -0.16s
  & > :last-child
    -webkit-animation-delay: 0.16s
    animation-delay: 0.16s

@-webkit-keyframes sk-bouncedelay
  0%, 80%, 100%
    -webkit-transform: scale(0)
  40%
    -webkit-transform: scale(1.0)

@keyframes sk-bouncedelay
  0%, 80%, 100%
    -webkit-transform: scale(0)
    transform: scale(0)
  40%
    -webkit-transform: scale(1.0)
    transform: scale(1.0)
