@import 'base'
$toggle-small-width:           26px
$toggle-small-height:          14px
$toggle-small-circle-size:     12px
$toggle-small-circle-spacing:  1px

$toggle-medium-width:          35px
$toggle-medium-height:         20px
$toggle-medium-circle-size:    16px
$toggle-medium-circle-spacing: 2px

$toggle-large-width:           43px
$toggle-large-height:          24px
$toggle-large-circle-size:     19px
$toggle-large-circle-spacing:  3px

// colours: override
$off-bg:        $gray-light
$label-color:   black
$knob-color:    white
$on-bg:         $color-blue-dark

$on-bg-red:         red


.Toggle
  + label
    display: inline-block !important
    margin-left: 12px !important
  .toggle-switch
    position: absolute
    visibility: hidden

    + label
      position: relative
      display: inline-block
      background-color: $off-bg
      color: $label-color
      transition: background 0.4s
      cursor: pointer
      outline: none
      text-indent: -99999px
      vertical-align: middle
      margin: 0

      &.small
        height: $toggle-small-height
        width: $toggle-small-width
        border-radius: $toggle-small-height

        &:after
          height: $toggle-small-circle-size
          width: $toggle-small-circle-size

          top: $toggle-small-circle-spacing
          left: $toggle-small-circle-spacing
          bottom: $toggle-small-circle-spacing

      &.medium
        height: $toggle-medium-height
        width: $toggle-medium-width
        border-radius: $toggle-medium-height

        &:after
          height: $toggle-medium-circle-size
          width: $toggle-medium-circle-size

          top: $toggle-medium-circle-spacing
          left: $toggle-medium-circle-spacing
          bottom: $toggle-medium-circle-spacing

      &.large
        height: $toggle-large-height
        width: $toggle-large-width
        border-radius: $toggle-large-height

        &:after
          height: $toggle-large-circle-size
          width: $toggle-large-circle-size

          top: $toggle-large-circle-spacing
          left: $toggle-large-circle-spacing
          bottom: $toggle-large-circle-spacing

      &:after
        position: absolute
        display: block
        background-color: $knob-color
        transition: margin 0.4s, background 0.4s
        content: ""
        border-radius: 50%

    &:checked + label
      background-color: $on-bg

    &:checked + label.small:after
      margin-left: $toggle-small-circle-size - 2px

    &:checked + label.medium:after
      margin-left: $toggle-medium-circle-size

    &:checked + label.large:after
      margin-left: $toggle-large-circle-size

    &[disabled] + label
      opacity: 0.5
      cursor: not-allowed

  &.danger
    .toggle-switch:checked + label
      background-color: $on-bg-red
