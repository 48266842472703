@import 'base'
#flash-messages
  position: absolute
  z-index: 9999999
  bottom: 1em
  right: 1em
  width: 300px

  .flash-message
    padding: 15px
    margin-bottom: 20px
    border: 1px solid transparent
    border-radius: 4px
    &.info
      background-color: #d9edf7
      border-color: #bce8f1
      color: #31708f
    &.success
      background-color: #dff0d8
      border-color: #d6e9c6
      color: #3c763d
    &.danger, &.error
      background-color: #f2dede
      border-color: #ebccd1
      color: #a94442
    &.warning
      background-color: #fcf8e3
      border-color: #faebcc
      color: #8a6d3b


    &.flash-enter
      max-height: 0
      overflow: hidden
      opacity: 0
      transform: translate(0,100%)
      &.flash-enter-active
        max-height: 100px
        opacity: 1
        transform: translate(0,0)
        transition: all 200ms ease
    &.flash-leave
      max-height: 100px
      opacity: 1
      transform: translate(0,0)
      &.flash-leave-active
        max-height: 0
        overflow: hidden
        opacity: 0
        transform: translate(0,100%)
        transition: all 200ms ease
