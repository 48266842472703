@import 'base'
.Pager

  .active
    a
      color: white
      background: $color-mw-blue
      border-color: $color-mw-blue


  ul.pagination
    display: inline-flex
    width: auto
    margin-top: 0

    a
      cursor: pointer


  .jump-input
    height: 32px
    width: 64px
    border-radius: 0px

    border-color: $gray-light
    margin-left: 2px
