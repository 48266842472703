@import 'base'
.Filters
  margin: 16px 0
  padding: 0 32px
  > label
    margin: 0 1em 0 2em

  h3
    display: inline-block
    margin: 0 1em 0 0

  .Filter
    // display: inline-block
    > label
      margin: 0 1em 0 2em
      display: inline-block

    > input[type=text], > input[type=search], > input[type=number]
      // -webkit-appearance: none
      // display: inline-block
      vertical-align: middle
      min-width: 150px
      max-width: 250px
      height: 36px

      border-color: $gray-light

    &.search
      > label
        display: none
      > input
        min-width: 250px

    &.amount
      > input
        min-width: 50px
        max-width: 74px

  .time-selector, .state-selector
    display: inline-block
    vertical-align: middle
    min-width: 150px
    .Select-control
      border-color: $gray-light
