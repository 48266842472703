@import 'base'
.WithdrawalRow, .WithdrawalRowHeader

  .id-container
    display: flex
    flex-direction: row

  .Header, .Cell
    width: 100px
    text-align: left
    &.id, &.id-button-col
      width: 100px
    &.user, &.receiver
      flex: 1
      +ellipsize
    &.amount
      width: 80px
      text-align: right
    &.balance
      width: 150px
      text-align: right
    &.actions
      width: 80px
      text-align: center

.WithdrawalModalContent
  .form-group
    min-height: 45px
    display: flex
    label
      width: 200px
    .form-control-static
      flex: 1
      padding-left: 20px
