@import 'base'
@import url(https://fonts.googleapis.com/css?family=Montserrat)

$icon-font-path: '/../node_modules/bootstrap-sass/assets/fonts/bootstrap/'
@import 'bootstrap-sass/assets/stylesheets/_bootstrap.scss'

@import '/styles/reset.sass'

html, body
  height: 100%

#application
  height: 100%
  display: flex
  flex-direction: column
  #app, > div
    flex: 1
    display: flex
    align-items: stretch
    #main-nav
      // overflow: scroll
      // width: 270px
      // +tablet // neat! also can use +only-tablet
      //   width: 100px
      //   overflow: hidden
      // +phone // neat!
      //   width: 50px
      //   overflow: hidden
      // background: $color-gray
      // color: white
      // box-shadow: 1px 0 0 0 #e6e6e6
      // img
      //   border-radius: 50%
      // li
      //   a
      //     color: white
      //     &.active
      //       color: red
    > main
      flex: 1
      overflow: scroll

  .app-loading
    justify-content: center


table.table tr td
  vertical-align: middle
  &.id-button-col
    width: 95px
  &.user-col
    width: 200px

.label
  display: inline-block
  padding: .25em .4em
  font-size: 75%
  font-weight: 700
  line-height: 1
  color: #fff
  text-align: center
  white-space: nowrap
  vertical-align: baseline
  border-radius: .25rem
  background-color: $default-bg
  &.default, &.label-default
    background-color: $default-bg
  &.primary, &.label-primary
    background-color: $primary-bg
  &.success, &.label-success
    background-color: $success-bg
  &.info, &.label-info
    background-color: $info-bg
  &.warning, &.label-warning
    background-color: $warning-bg
  &.danger, &.label-danger
    background-color: $danger-bg
