@import 'base'
.CommissionCodes
  flex: 1
  header
    display: flex
    align-items: center
    box-shadow: 0 1px 0 0 $gray-light
    height: 60px
    > span
      margin: 0
      padding: 0 1.2em
    h1
      margin: 0
      padding: 0 1.2em
      // display: inline-block
      flex: 1
    .search-form
      margin: 0
      padding: 0
      padding-right: 1.2em
      width: 300px
      input
        font-size: 1.4em

  +filters

  section.content
    .filters
      margin: 2em 2em 0
    .List
      margin-bottom: 2em

      .Header, .Cell
        flex-shrink: 0

        &.currency
          width: 100px
          span.label
            font-size: 1em
            font-weight: normal
            margin-left: 1em
            color: $danger-bg
            background-color: white
            border-radius: 4px
            border: 1px solid $danger-bg
        &.code
          flex: 1
        &.flat, &.max, &.fee_amount
          width: 100px
        &.percent, &.broker_fee
          width: 80px
        &.rebate_user_id
          width: 175px
        &.actions
          width: 80px
          text-align: center

.OrderLevelFeeCodes
  flex: 1
  header
    display: flex
    align-items: center
    box-shadow: 0 1px 0 0 $gray-light
    height: 60px
    > span
      margin: 0
      padding: 0 1.2em
    h1
      margin: 0
      padding: 0 1.2em
      // display: inline-block
      flex: 1
    .search-form
      margin: 0
      padding: 0
      padding-right: 1.2em
      width: 300px
      input
        font-size: 1.4em

  +filters

  section.content
    .filters
      margin: 2em 2em 0
    .List
      margin-bottom: 2em

      .Header, .Cell
        flex-shrink: 0

        &.currency
          width: 100px
          span.label
            font-size: 1em
            font-weight: normal
            margin-left: 1em
            color: $danger-bg
            background-color: white
            border-radius: 4px
            border: 1px solid $danger-bg
        &.code
          flex: 1
        &.fees
          display: flex
          flex: 2
        &.actions
          width: 80px
          text-align: center

.NewCode, .NewAdvance, .NewPartnershipPayment
  .commission-label
    white-space: nowrap
    text-overflow: ellipsis
  .form-group
    min-height: 45px
    label
      line-height: 45px
  .buttons, .footer-buttons
    text-align: right
    .btn
      margin-left: 1em
  textarea
    margin-bottom: 15px

.NewCodeModal
  padding: 0px 40px 20px 40px
  .d-flex
    display: flex
  .flex-fill
    flex: 1
  .flex-column
    flex-direction: column
  .NewCode
    .required:after
      content: "*"
      color: red
    .section-heading
      font-weight: bold
    .add-property
      text-align: right
      margin-bottom: 20px
    .rule-button
      width: 100%
      margin-bottom: 20px
      background: transparent
      border: 1px solid $primary-blue
      border-radius: 6px
      height: 36px
      font-style: normal
      font-weight: 400
      font-size: 16px
      line-height: 20px
      color: $primary-blue
    .section-line
      // width: 100%
      width: calc(100% + 80px)
      margin-left: -40px
    .trash-icon
      width: 20px
      height: 20px
      align-self: center
    .add-property
      line-height: 20px
      font-family: Montserrat
      font-weight: 400
      .property-text
        font-size: 16px
        color: $primary-blue
      .dd-menu-items
        font-style: normal
        ul
          li
            a
              font-size: 14px
    .footer-buttons
      .btn
        height: 36px
        font-family: Montserrat
        font-weight: 400
        font-size: 16px
        line-height: 20px
        border-radius: 2px
      .btn-default
        color: #1C232B
      .btn-primary
        background-color: $primary-blue
    .modal-body
      overflow-y: scroll
      height: calc(100vh - 300px)
    .section-heading
      font-weight: 600
      font-size: 16px
      line-height: 20px
      color: #1C232B
    .form-group
      .input-group
        input
          font-weight: 400
          font-size: 16px
          line-height: 20px
          border: 1px solid $gray-80
          border-radius: 0px 2px 2px 0px
        .input-group-addon
          background: $gray-light
          font-weight: 400
          font-size: 16px
          line-height: 20px
      .fee-amount
        .form-control
          font-weight: 400
          font-size: 16px
          line-height: 20px
          border: 1px solid $gray-80
          border-radius: 0px 2px 2px 0px
      .Select
        .Select-control
          height: 45px
          border: 1px solid $gray-80
          border-radius: 2px 2px 0px 0px
          .Select-value
            line-height: 45px
            font-size: 16px
            color: #333333
            font-family: Montserrat
          .Select-placeholder
            font-size: 14px
            color: #AAAAAA
            font-family: Montserrat

    .commission-label, .control-label
      flex: 1
      color: #1C232B
      line-height: 20px
      font-size: 16px
      margin-top: 12px
    .fee-amount
      flex: 2
    .condition
      display: flex
      flex-direction: row
      alignItems: center
      justify-content: space-between
      position: relative
      .property-name
        flex: 1
      .comparison
        flex: 0
      .value
        flex: 2 1 5px
      .without-trash
        margin-right: -13px
      .trash-can
        position: relative
        right: -20px
        display: flex
        justify-content: center
        align-items: center
