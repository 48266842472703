@import 'base'
main section.content .Notes
  +filters
  .Header, .Cell
    &.actions
      width: 80px
    &.id, &.amount, &.created-at, &.target
      width: 80px
      text-align: left
      +ellipsize

    &.user
      flex: 1
      +ellipsize

    &.text
      flex: 2
      +ellipsize
