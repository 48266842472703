@import 'base'
.EventCard
  display: flex

  .event-details
    display: inline-block
    vertical-align: middle
    line-height: 1.1em
    .When
      font-size: 0.8em
      color: $gray
