@import 'base'
.Tickets
  +filters
  flex: 1
  header
    display: flex
    align-items: center
    box-shadow: 0 1px 0 0 $gray-light
    height: 60px
    h1
      margin: 0
      padding: 0
      padding-left: 1.2em
      // display: inline-block
      flex: 1

  section.content
    .filters
      margin: 2em 2em 0
    .List
      margin-bottom: 2em

      .Header, .Cell
        &.id, &.id-button-col
          width: 80px
          text-align: left
        &.price, &.created_at
          width: 80px
          text-align: right
        &.actions
          width: 80px
          text-align: center
        &.num_cost_items
          width: 40px
          text-align: center
        &.amount
          width: 80px
          text-align: right
        &.user
          flex: 1
          +ellipsize
        &.listing
          flex: 2
          +ellipsize
        &.messages
          width: 100px
          text-align: center
          .btn + .btn
            margin-top: 0.66em

.Ticket
  +filters
  flex: 1
  > header
    display: flex
    align-items: center
    box-shadow: 0 1px 0 0 $gray-light
    height: 60px
    h1
      margin: 0
      padding: 0
      padding-left: 1.2em
      flex: 1

  main
    display: flex
    align-items: stretch
    section.content
      flex: 1
      .Summary
        margin: 20px

      .Summary
        .CostItemCard
          margin: 20px
        .details
          display: flex
          .detail
            margin-right: 2em
            flex: 1
          .panel
            .EventCard img, .UserCard img
              margin-left: 0
          .tags .label
            font-size: 1.1em
            margin: 0 1em 1em 0
            background-color: white
            border: 1px solid white
            border-color: $info-bg
            color: $info-bg
            &[disabled]
              border-color: $default-bg
              color: $default-bg
              opacity: 0.33

        .unii-answer
          margin: 1em 0

        .more
          display: flex
          > *
            flex: 1
            min-width: 450px
            margin-right: 2em

          .attributes
            .form-group
              display: flex
              flex-direction: row
              label
                width: 150px
                line-height: 50px
              input
                width: auto
                flex: 1



      .CostItems
        .cost-item-row
          display: flex
          justify-content: space-between

      .PrintingLogs
        margin: 20px 0
        .Filters
          margin: 0 20px 10px
          padding: 0
        .List
          .Header, .Cell
            &.by_who
              flex: 1
              +ellipsize
            &.id
              width: 70px
            &.page
              width: 120px
            &.source
              width: 60px
            &.date
              width: 80px
              text-align: right

      .Emails
        .List
          .Header, .Cell
            &.id
              width: 80px
              text-align: left
            &.btn, &.created_at
              width: 80px
              text-align: right
            &.download, &.opens, &.clicks
              width: 40px
              text-align: center
            &.actions
              width: 80px
              text-align: center
            &.subject, &.to
              flex: 1
              +ellipsize

