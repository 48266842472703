@import 'base'
.List
  width: 100%
  display: flex
  flex-direction: column
  > .HeaderRow
    display: flex
    justify-content: space-between
    align-items: center
    width: 100%
    padding: 10px 10px 0px 10px
    border-bottom: 3px solid #f0f0f0
    > .Header
      margin: 0 10px
      font-weight: bold
  > .Row
    display: flex
    justify-content: space-between
    align-items: center
    width: 100%
    padding: 0 10px
    border-bottom: 1px solid #f0f0f0
    &:nth-child(even)
      background: #fafafc
    > .Cell
      box-sizing: border-box
      padding: 16px 0
      margin: 0 10px
      small
        display: block
        color: #707070
      &:hover
        color: black
