@import 'base'
.NoteComponent
  position: relative
  margin: 1em
  padding: 1em
  min-height: 120px
  &.active
    border-left: 10px solid #f1d445
    box-shadow: 3px 3px 15px #DBE2ED
    border-radius: 5px

  &.resolved
    border-left: 10px solid green
    box-shadow: 3px 3px 15px #DBE2ED
    border-radius: 5px

  .text
    padding: 1em

  .dropdown
    position: absolute
    top: 10px
    right: 10px

  .UserCard
    position: absolute
    bottom: 10px
    right: 10px

.NoteCreate
  textarea
    margin-bottom: 1em
    height: 150px

.NoteWarning
  margin-top: 4px
  display: flex
  flex-direction: row
  justify-content: left
  svg
    margin: 0px 2px 0px 2px
    color: #f1d445
    background: white
    zoom: 1.2
