@import 'base'
.Transfers
  +filters
  flex: 1
  header
    display: flex
    align-items: center
    box-shadow: 0 1px 0 0 $gray-light
    height: 60px
    h1
      margin: 0
      padding: 0
      padding-left: 1.2em
      // display: inline-block
      flex: 1

  section.content
    .filters
      margin: 2em 2em 0
    .List
      margin-bottom: 2em

      .Header, .Cell
        flex-shrink: 0
        &.id,&.original_cost_item,&.new-cost-item,&.state
          width: 80px
        &.transfer-to,&.accepted-by,&.buyer
          width: 120px
        &.event-name
          width: 150px
        &.transferred-date,&.accepted-date
          width: 80px
