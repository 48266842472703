=phone
  @media only screen and (max-width: $screen-phone)
    @content

=tablet
  @media only screen and (max-width: $screen-tablet)
    @content

=laptop
  @media only screen and (max-width: $screen-desktop)
    @content

=desktop
  @media only screen and (max-width: $screen-lg-desktop)
    @content


=only-phone
  @media only screen and (max-width: $screen-phone)
    @content

=only-tablet
  @media only screen and (max-width: $screen-tablet) and (min-width: $screen-phone + 1)
    @content

=only-laptop
  @media only screen and (max-width: $screen-desktop) and (min-width: $screen-tablet + 1)
    @content

=only-desktop
  @media only screen and (max-width: $screen-lg-desktop) and (min-width: $screen-desktop + 1)
    @content


=hidden-phone
  +phone
    display: none

=hidden-tablet
  +tablet
    display: none

=hidden-laptop
  +laptop
    display: none

=hidden-desktop
  +desktop
    display: none

=loading
  transition: opacity 0.3s
  &[data-loading=true]
    opacity: 0.7


=biglist
  margin: 16px 0
  > *
    display: flex
    align-items: center
    width: 100%
    border-bottom: 1px solid $border-color
    &:nth-child(even)
      background: #fafafc
    > *
      box-sizing: border-box
      padding: 16px 0
      margin: 0 16px
      &:first-child
        padding-left: 16px
      &:last-child
        padding-right: 16px
      small
        display: block
        color: #707070
      &:hover
        color: black

=ellipsize
  text-overflow: ellipsis
  overflow: hidden
  white-space: nowrap


=keyframes($name)
  @-webkit-keyframes #{$name}
    @content
  @-moz-keyframes #{$name}
    @content
  @-ms-keyframes #{$name}
    @content
  @keyframes #{$name}
    @content

+keyframes(spin)
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)

=spin
  animation-name: spin
  animation-duration: 5s
  animation-iteration-count: infinite
  animation-timing-function: linear

=filters
  .filters
    padding-bottom: 1em
    h3
      display: inline-block
      margin: 0 1em 0 0
    label
      margin: 0 1em 0 2em
    .filter
      font-size: 1.25em
      margin-right: 1em


    .filter-selector
      display: inline-block
      vertical-align: middle
      min-width: 150px
      .Select-control
        border-color: $gray-light

    .filter-input
      display: inline-block
      vertical-align: middle
      min-width: 150px
      max-width: 250px
      height: 36px

      border-color: $gray-light
