@import 'base'
.ICPQueue
  +filters
  flex: 1

  header
    display: flex
    align-items: center
    box-shadow: 0 1px 0 0 $gray-light
    height: 60px
    h1
      margin: 0
      padding: 0
      padding-left: 1.2em
      flex: 1

  section.content

    .filters
      margin: 2em 2em 0
    .List
      margin-bottom: 2em
      .Header, .Cell
        &.user
          flex: 1
          +ellipsize
        &.created
          width: 80px
          text-align: right
        &.manual_ref
          width: 100px
        &.id
          width: 80px
          text-align: left
        &.actions
          width: 200px
          text-align: center

  button:focus
    outline: 0
