@import 'base'
.Notes
  flex: 1
  header
    display: flex
    align-items: center
    box-shadow: 0 1px 0 0 $gray-light
    height: 60px
    h1
      margin: 0
      padding: 0
      padding-left: 1.2em
      flex: 1
    .search-form
      margin: 0
      padding: 0
      padding-right: 1.2em
      width: 300px
      input
        font-size: 1.4em

  section.content
    .filters
      margin: 2em 2em 0

    .List
      margin-bottom: 2em

      .Header, .Cell
        &.actions
          width: 80px
        &.id, &.amount, &.created-at, &.target
          width: 80px
          text-align: left
          +ellipsize

        &.user
          flex: 1
          +ellipsize

        &.text
          flex: 2
          +ellipsize

  button:focus
    outline: 0

  // For filter styles
  +filters
