@import 'base'
.DropdownMenu
  .btn-universe
    background: $color-ticketmaster
  .dropdown-menu
    position: absolute
    z-index: 9999
    right: 0
    left: auto
  .caret
    margin-left: 1em
  .empty
    padding: 0 10px

  .dd-menu .dd-menu-items
    margin: .125rem 0 0
    ul
      min-width: 10rem
      padding: .5rem 0
      border: 1px solid rgba(0,0,0,.15)
      border-radius: .25rem
      box-shadow: none
      li a
        font-family: inherit
        padding: 3px 1.5rem
        font-size: 0.85em
        &:hover
          color: inherit
          text-decoration: none
          background-color: #f7f7f9
