@import 'base'
.Change
  display: block

  td.before, td.after
    max-width: 300px
    overflow: auto

  .label-target-type
    vertical-align: bottom
    margin-right: 1em
    background-color: white
    border: 1px solid white
    border-color: $info-bg
    color: $info-bg

  .id
    font-family: monospace
    margin-left: 0.5em
