@import 'base'
.ManualDislcosure
  flex: 1
  header
    display: flex
    align-items: center
    box-shadow: 0 1px 0 0 $gray-light
    height: 60px
    h1
      margin: 0
      padding: 0
      padding-left: 1.2em
      flex: 1

  section.content
    padding: 1em

  .input-container
    margin: 10px 0px
    display: flex
    align-items: center

    .justification-selector,
    .pii-selector,
    .fixed-width
      width: 350px

    textarea
      width: 50%
