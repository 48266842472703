//-----------------------------------------------------------------------------
// Colors
//-----------------------------------------------------------------------------

$color-mw-blue:    rgb(58, 102, 229)
$color-blue:       #00C2FF
$color-blue-dark:  #28AFFA
$color-blue-light: #4DD4FF
$color-pink:       #FF4776
$color-pink-dark:  #F03F6D
$color-pink-light: #FF5984
$color-gray:       #37393E
$color-white:      #FFFFFF

$color-red: #F0523C
$color-red-light: #FEF4F3

$color-ticketmaster: #009CDE

$gray-multiverse:  #2B2E30 // Gray I
$gray-placeholder: #BBC1C7

$gray-base:    #000000 // Black
$gray-darker:  #37393E // Gray II
$gray-dark:    #37393E // Gray II
$gray:         #78848F // Gray III
$gray-light:   #DBE2ED // Gray IV
$gray-lighter: #FAFAFA // Gray V
$gray-80:      #CCCCCC

$primary-blue: #3A66E5

//-----------------------------------------------------------------------------
// Transitions
//-----------------------------------------------------------------------------

$default-transition: cubic-bezier(0.645, 0.045, 0.355, 1)


//-----------------------------------------------------------------------------
// Font
//-----------------------------------------------------------------------------

$body-bg:               $color-white
$text-color:            $gray-dark
$link-color:            inherit
$link-hover-color:      inherit
$link-hover-decoration: none


//-----------------------------------------------------------------------------
// Typography
//-----------------------------------------------------------------------------

$font-family-sans-serif: "Proxima Nova", "proxima-nova", "Montserrat", montserrat, sans-serif
$font-family-serif:      Georgia, "Times New Roman", Times, serif
$font-family-monospace:  Menlo, Monaco, Consolas, "Courier New", monospace
$font-family-base:       $font-family-sans-serif

$font-size-base:  13px
$font-size-large: 16px
$font-size-small: 11px

$font-size-h1: 20px
$font-size-h2: 18px
$font-size-h3: 16px
$font-size-h4: $font-size-h3
$font-size-h5: $font-size-base
$font-size-h6: ceil(($font-size-base * 0.85))

$line-height-base:     1.428571429
$line-height-computed: floor(($font-size-base * $line-height-base))

$headings-font-family: inherit
$headings-font-weight: 400
$headings-line-height: 1.1
$headings-color:       $gray-dark


//-----------------------------------------------------------------------------
// Components
//-----------------------------------------------------------------------------

$padding-base-vertical:    6px
$padding-base-horizontal:  12px
$padding-large-vertical:   20px
$padding-large-horizontal: 16px
$padding-small-vertical:   5px
$padding-small-horizontal: 10px
$padding-xs-vertical:      1px
$padding-xs-horizontal:    5px

$line-height-large: 1.3333333
$line-height-small: 1.5

$border-radius-base:  3px
$border-radius-large: 8px
$border-radius-small: 2px
$border-color:        $gray-light


//-----------------------------------------------------------------------------
// Buttons
//-----------------------------------------------------------------------------

$btn-font-weight: normal

$btn-default-color:  $gray
$btn-default-bg:     #fff
$btn-default-border: $gray-light

$btn-primary-color:  #fff
$btn-primary-bg:     $color-ticketmaster
$btn-primary-border: $color-ticketmaster

$btn-success-color:  #fff
$btn-success-bg:     $color-ticketmaster
$btn-success-border: $color-ticketmaster

$btn-info-color:  #fff
$btn-info-bg:     $color-ticketmaster
$btn-info-border: $color-ticketmaster

$btn-warning-color:  #fff
$btn-warning-bg:     $color-pink
$btn-warning-border: $color-pink

$btn-danger-color:  #fff
$btn-danger-bg:     $color-pink
$btn-danger-border: $color-pink

$btn-link-disabled-color: $gray-light


//-----------------------------------------------------------------------------
// Forms
//-----------------------------------------------------------------------------

$input-bg: #FFF
$input-bg-disabled: $gray-lighter
$input-color: $gray-dark
$input-border: $gray-light
$input-border-radius: 4px
$input-border-radius-large: $border-radius-large
$input-border-radius-small: $border-radius-small
$input-border-focus: $color-ticketmaster
$input-color-placeholder: rgba($gray, 0.5)
$input-height-base: 45px
$input-height-large: (ceil($font-size-large * $line-height-large) + ($padding-large-vertical * 2) + 2)
$input-height-small: (floor($font-size-small * $line-height-small) + ($padding-small-vertical * 2) + 2)
$legend-color: $gray
$legend-border-color: #e5e5e5
$input-group-addon-bg: $gray-lighter
$input-group-addon-border-color: $input-border
$cursor-disabled: not-allowed


//-----------------------------------------------------------------------------
// Media Query Breakpoints
//-----------------------------------------------------------------------------

$screen-xs: 480px
$screen-xs-min: $screen-xs
$screen-phone: $screen-xs-min
$screen-sm: 768px
$screen-sm-min: $screen-sm
$screen-tablet: $screen-sm-min
$screen-md: 992px
$screen-md-min: $screen-md
$screen-desktop: $screen-md-min
$screen-lg: 1200px
$screen-lg-min: $screen-lg
$screen-lg-desktop: $screen-lg-min
$screen-xl: 1440px
$screen-xl-min: $screen-xl
$screen-xl-desktop: $screen-xl-min
$screen-xs-max: ($screen-sm-min - 1)
$screen-sm-max: ($screen-md-min - 1)
$screen-md-max: ($screen-lg-min - 1)


//-----------------------------------------------------------------------------
// Grid System
//-----------------------------------------------------------------------------

$grid-columns: 12
$grid-gutter-width: 70px
$grid-gutter-width-small: 20px
$grid-float-breakpoint: $screen-sm-max
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1)


//-----------------------------------------------------------------------------
// Container Sizes
//-----------------------------------------------------------------------------

$container-tablet: (720px + $grid-gutter-width)
$container-sm: $container-tablet
$container-desktop: (940px + $grid-gutter-width)
$container-md: $container-desktop
$container-large-desktop: (1140px + $grid-gutter-width)
$container-lg: $container-large-desktop



$default-bg: #818a91
$primary-bg: #0275d8
$success-bg: #5cb85c
$info-bg:    #5bc0de
$warning-bg: #f0ad4e
$danger-bg:  #d9534f
