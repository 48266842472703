@import 'base'
.Listings
  flex: 1
  > header
    display: flex
    align-items: center
    box-shadow: 0 1px 0 0 $gray-light
    height: 60px
    h1
      margin: 0
      padding: 0
      padding-left: 1.2em
      // display: inline-block
      flex: 1
    .search-form
      margin: 0
      padding: 0
      padding-right: 1.2em
      width: 300px
      input
        font-size: 1.4em

  +filters

  section.content
    .filters
      margin: 2em 2em 0
    .List
      margin-bottom: 2em

      .Header, .Cell
        flex-shrink: 0
        &.id
          width: 80px
          text-align: left
        &.title
          flex: 1
          +ellipsize
        &.host
          width: 250px
          +ellipsize
        &.created, &.price
          width: 80px
          text-align: right
        &.actions
          width: 80px
          text-align: center

.Listing
  flex: 1
  > header
    display: flex
    align-items: center
    box-shadow: 0 1px 0 0 $gray-light
    height: 60px
    h1
      margin: 0
      padding: 0
      padding-left: 1.2em
      flex: 1
    .spam-button
      display: inline-block
      margin-left: 10px

  main
    display: flex
    align-items: stretch
    section.content
      flex: 1

      .Summary
        margin: 20px

        .details
          display: flex
          .detail
            margin-right: 2em
            flex: 1
          .panel
            .EventCard img, .UserCard img
              margin-left: 0
          .label
            font-size: 1.1em
            margin: 0 1em 1em 0
            background-color: white
            border: 1px solid white
            border-color: $info-bg
            color: $info-bg
            &[disabled]
              border-color: $default-bg
              color: $default-bg
              opacity: 0.33

        .report-exports, .actions, .settings
          .Toggle
            margin-right: 1em
          .alert
            display: inline-block
          ul
            list-style: none
            padding: 0

        .settings
          ul li
            margin: 1em 0

        .report-exports, .actions
          ul li
            display: inline-block
            margin: 0 0.5em 0.5em 0
            svg
              margin-right: 0.5em
            a
              color: inherit

      .filters
        margin: 2em 2em 0


  .Duplications
    padding: 2em
    .dupeForm
      display: flex
      > .left
        width: 430px
      > .right
        flex: 1
        > .form-group
          margin-bottom: 8px
    .List
      .Cell
        &.id, &.actions
          width: 100px
        &.what
          flex: 2
        &.when
          flex: 1
        &.actions
          text-align: right

  .Events
    .List
      margin-bottom: 2em
      .Header, .Cell
        &.id
          width: 80px
        &.when
          flex: 1
          +ellipsize
        &.num_tickets
          width: 120px
          text-align: center
        &.actions
          width: 100px
          text-align: center

  .Rates
    h2
      margin: 20px
    .List
      .Header, .Cell
        &.id, &.commission-code, &.order-fee-code
          width: 200px
        &.select
          width: 400px
        &.name, &.price, &.fees
          flex: 1
          +ellipsize
      .rate-selector
        min-width: 150px
        border-top-right-radius: 0px
        border-bottom-right-radius: 0px

  .DiscountCodes, .AccessKeys
    .List
      margin-bottom: 2em
      .Header, .Cell
        &.code, &.key
          width: 150px
        &.discount, &.uses, &.applies_to
          width: 120px
          text-align: center
        &.actions
          width: 100px
          text-align: center

  .seatsMessage
    font-size: 18px
    margin: 12px 0 0 12px

  .Ticketmaster
    margin: 20px
    .TicketmasterVenue
      .change-venue
        padding: 0 20px 20px 20px
        border: 1px solid $warning-bg
        margin-bottom: 20px
        .venue-results
          margin-top: 10px
      .clear-venue
        padding: 0 20px 20px 20px
        border: 1px solid $danger-bg
      .venue-selector
        border: 1px solid $gray-lighter
        border-radius: 5px
        padding: 10px 20px
        h3
          margin-top: 0
        label
          width: 50%
          .filter-selector
            width: 100%
            display: inline-block
      .btn-container
        margin-bottom: 18px
    .TicketmasterAttractions
      border-top: 1px solid $gray-light
      margin: 20px 0
      .edit-attractions
        border: 1px solid $gray-light
        border-radius: 5px
        padding: 10px 20px
        background: $gray-lighter
        padding-bottom: 20px
      .attraction-search, .current-attractions
        background: white
        border: 1px solid $gray-lighter
        border-radius: 5px
        padding: 10px 20px
        margin: 20px 0
        .results
          padding: 1px 15px
          border: 1px solid $gray-light
          border-radius: 5px
          margin-bottom: 10px
          .result
            margin: 15px 0
            button
              margin-right: 5px
              background: $success-bg
              color: white
              border: none
              border-radius: 3px
      .current-attractions
        .results
          margin-top: 20px
          .result button
            background: $danger-bg
      .attraction-search
        input
          margin-top: 20px
          padding: 10px 20px
          border: 1px solid $gray-light
          border-radius: 0
          border-top-left-radius: 5px
          border-top-right-radius: 5px
          box-shadow: none
        .results
          border-top: none
          border-top-left-radius: 0
          border-top-right-radius: 0
      .btn-container
        margin-bottom: 18px

  .Seats
    margin: 20px
    .form-inline
      display: inline-block
    main h3
      margin: 0
    .input-group
      width: 532px
    h1.actions
      display: inline-block
      margin: 9px 12px 9px 0px
      vertical-align: middle
      font-weight: bolder
    hr
      border-top: 1px solid #f0f0f0
    .List.seats-checklist
      width: 40%
    .List
      width: 66%
      .HeaderRow
        > .Header.id
          width: 40%
        > .Header.name
          width: 50%
        > .Header.capacity
          width: 10%
      .Row
        > .Cell.id
          width: 40%
        > .Cell.name
          width: 50%
        > .Cell.capacity
          width: 10%
      .HeaderRow.top
        justify-content: flex-start
        background-color: #f0f0f0
        padding: 5px 0px 5px 10px
        margin-bottom: 5px
        .Header
          font-size: 14px

  button[disabled] svg
    +spin

  .BinRestriction
    textarea
      width: 50%

    textarea,
    .clear-bins,
    .bin-restriction-copy
      margin: 25px
  +filters

  .Import
    margin: 20px
    table
      margin: 5px
    td
      border: 1px solid black
      padding: 2px
    .NextButton
      padding: 8px 14px
      background: $color-mw-blue
      color: white
      border-radius: 5px
      margin-top: 20px
      display: block

    label
      display: block
    .csv-format
      border: 1px solid $gray-lighter
      background: #efefef
      font-family: monospace
      padding: 10px
      font-weight: bold
    .data-section
      border: 1px solid $gray-lighter
      padding: 20px 30px
      border-radius: 5px
      margin-bottom: 20px

      h3
        font-weight: bold
        margin: 0
        margin-bottom: 1em

      label
        display: block
        margin-top: 20px
        font-size: 1.1em
        input
          display: block
          margin-left: 0px
          font-size: 0.8em
          &::placeholder
            font-weight: normal
            font-style: italic
            color: $gray-lighter !important
        input[type='file']
          padding: 0
        input[type=checkbox]
          display: inline-block
          margin-right: 10px
        span
          color: black
      table
        margin-left: 0
        margin-bottom: 10px
        thead
          font-weight: bold
        td
          padding: 20px 5px
    .import-progress
      font-size: 1.2em
      font-weight: bold
      .import-progress-bar
        background: $gray-lighter
        width: 100%
        height: 30px
        display: inline-block
        overflow: hidden
        border-radius: 5px
        div
          background: $color-mw-blue
          float: left
          height: 100%

.Kickback
  flex: 1
  header
    display: flex
    align-items: center
    box-shadow: 0 1px 0 0 $gray-light
    height: 60px
    h1
      margin: 0
      padding: 0
      padding-left: 1.2em
      flex: 1
    .search-form
      margin: 0
      padding: 0
      padding-right: 1.2em
      width: 300px
      input
        font-size: 1.4em

  .message
    margin: 20px

  section.content
    padding: 1em

  button:focus
    outline: 0

  .event-table-title
    display: flex
    align-items: center
    div
      margin-right: 10px

  // For filter styles
  +filters

  .KickbackRow
    &.kickback-totals
      border-top: 2px solid #DDDDDD

    .id-container
      display: flex
      flex-direction: row

    .name
      width: 300px

    .totals
      font-weight: bold

    button:focus
      outline: 0

    table
      td
        vertical-align: middle
        font-size: 1.1em
        a
          color: $text-color
          background-color: transparent
          &:hover
            color: $color-ticketmaster
