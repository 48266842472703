@import 'base'
.ReportLogs
  +filters
  flex: 1
  header
    display: flex
    align-items: center
    box-shadow: 0 1px 0 0 $gray-light
    height: 60px
    h2
      padding-left: 1.2em
    h1
      margin: 0
      padding: 0
      padding-left: 1.2em
      // display: inline-block
      flex: 1
    .search-form
      margin: 0
      padding: 0
      padding-right: 1.2em
      width: 300px
      input
        font-size: 1.4em


  .Header, .Cell
    width: 100px
    text-align: left
    &.id, &.id-button-col
      width: 100px
    &.user, &.scope
      flex: 1
      +ellipsize
    &.klass
      width: 180px

  section.content
    .filters
      margin: 2em 2em 0
