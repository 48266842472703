@import 'base'
=success
  border-color: $success-bg
  color: $success-bg
=info
  border-color: $info-bg
  color: $info-bg
=warning
  border-color: $warning-bg
  color: $warning-bg
=danger
  border-color: $danger-bg
  color: $danger-bg


.StateLabel, .StateActions
  // color: white
  // font-weight: bold
  background-color: white !important
  border-radius: 4px
  padding: 4px 8px
  border: 1px solid white
  &.hidden
    display: inline-block !important // LOL bootstrap
  &.active
    box-shadow: none

  &:hover, &:focus, &:active
    background-color: white !important

  &.User
    &.active
      +success
    &.unconfirmed
      +info
    &.locked
      +warning
    &.deleted
      +danger

  &.Event
    &.full
      +success
    &.normal
      +info
    &.unbooked
      +warning
    &.hidden
      +danger

  &.CostItem
    &.paid, &.ended, &.closed
      +success
    &.expired, &.declined
      +info
    &.tranferred, &.unapproved, &.pending
      +warning
    &.cancelled, &.unpaid, &.failed, &.error, &.needs_pre_auth
      +danger

  &.Listing
    &.posted
      +success
    &.draft, &.expired, &.inactive, &.archived
      +info
    &.presubmitted
      +warning
    &.declined, &.hidden
      +danger

  &.Ticket
    &.paid, &.ended, &.closed
      +success
    &.expired, &.declined
      +info
    &.unpaid, &.unapproved, &.pending, &.partially_paid, &.unauthorized
      +warning
    &.cancelled, &.failed, &.error, &.disputed, &.needs_pre_auth
      +danger

  &.Transfer
    &.accepted
      +success
    &.pending
      +info
    &.cancelled
      +warning
    &.declined
      +danger

  // For withdrawal model
  &.Withdrawal
    &.approved
      +info
    &.paid
      +success
    &.pending
      +warning
    &.failed, &.error
      +danger

  // For withdrawal model
  &.Chargeback
    &.won
      +success
    &.open
      +warning
    &.lost
      +danger

  &.Email
    &.sent
      +success
    &.delivering
      +warning
    &.failed
      +danger

    &.delivered
      +success
    &.pending
      +info
    &.bounced
      +warning
    &.complaint
      +danger

  &.Installment
    &.paid
      +success
    &.unpaid
      +info
    &.refunded, &.cancelled
      +warning
    &.defaulted, &.failed
      +danger

  &.ServiceInvoice
    &.paid
      +success
    &.open, &.awaiting_payment
      +warning
    &.uncollected, &.cancelled
      +danger

  &.Note
    &.resolved
      +success
    &.active
      +warning

  &.CommissionCode
    &.pending
      +warning
    &.approved
      +success
    &.denied
      +danger

  &.DiscountCode
    &.active
      +info
    &.used
      +success
    &.inactive
      +warning

  &.AccessKey
    &.active
      +info
    &.used
      +success
    &.inactive
      +warning

  &.CheckInState
    &.checked_out
      +info
    &.manually_checked_in, &.scanned_in
      +success
    &.scanned_out
      +warning

  &.ReportLog
    &.new, &.queued, &.generating, &.running
      +info
    &.delivered
      +success
    &.failed
      +danger

  &.DelayedReleaseState
    &.unreleased
      +warning
    &.partially_released
      +info
    &.released
      +success
