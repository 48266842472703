@import 'base'
.ReportDownloadModal
  .modal-dialog
    width: 800px

  .header-title
    font-size: 20px
    line-height: 1.4
    word-break: break-all
    color: #1C232B

  .modal-body
    padding: 30px

    .selectors
      display: flex
      flex-wrap: wrap
      gap: 3rem

      .filter-selector
        width: 340px

    .calendar
      margin-top: 3rem

      .rdrCalendarWrapper
        margin-left: 3rem

      .rdrDefinedRangesWrapper
        display: none

  .modal-footer
    padding: 15px 25px

    .footer-container
      display: flex
      justify-content: space-between
      align-items: center

      .action-buttons
        display: flex
        justify-content: end

        .btn-primary
          color: #fff
          margin-left: 1.25rem
