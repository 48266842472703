@import 'base'
.Empty
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  margin: 2em
  padding: 2em
  min-height: 100px
  box-shadow: 0 0 1px 1px $gray-light
  border-radius: 5px
  background: $gray-lighter
  h1
    margin-top: 0
