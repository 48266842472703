@import 'base'
.CostItems
  +filters
  flex: 1
  header
    display: flex
    align-items: center
    box-shadow: 0 1px 0 0 $gray-light
    height: 60px
    h1
      margin: 0
      padding: 0
      padding-left: 1.2em
      flex: 1

  section.content
    .filters
      margin: 2em 2em 0
    .List
      margin-bottom: 2em
      .Header, .Cell
        &.id, &.id-button-col
          width: 80px
          text-align: left
        &.price, &.created_at
          width: 80px
          text-align: right
        &.actions
          width: 80px
          text-align: center
        &.user
          flex: 1
          +ellipsize
        &.listing
          flex: 1
          +ellipsize
        &.rate
          flex: 2

  .actionable
    cursor: pointer
