@import 'base'
.TicketMove
  div.date
    font-size: 1.33em
  div.capacity
    color: #555
  div.date-container
    display: flex
    align-items: center
    .date
      margin-right: 0.5em
