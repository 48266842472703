@import 'base'
.SignIn
  background: #F2F5F8
  position: absolute
  overflow: hidden
  width: 100vw
  height: 100vh

  display: flex
  flex-direction: column
  align-items: center
  justify-content: center

  i.fa-lock, .lock-icon
    color: #c8d9e6
    font-size: 4rem
    margin-bottom: 1rem
  h1
    margin-bottom: 4rem
    font-size: 2.5rem

  .sign-in-form
    width: 460px

    padding: 4rem

    background-color: white
    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.25)
    border-radius: 0.3rem
    +tablet
      width: 90%
    +phone
      width: 100%


  .help-text

    margin: 0 auto
    margin-top: 4rem
    text-align: center
    border-radius: 0.3rem
    padding: 2rem 4rem

    a
      color: $color-blue-dark
      font-size: 1.2em
      color: #333
      img.okta
        height: 1em
        display: inline
        vertical-align: text-bottom
        margin-left: 0.33em
