@import 'base'
.Events
  flex: 1
  header
    display: flex
    align-items: center
    box-shadow: 0 1px 0 0 $gray-light
    height: 60px
    h2
      padding-left: 1.2em
    h1
      margin: 0
      padding: 0
      padding-left: 1.2em
      // display: inline-block
      flex: 1
    .search-form
      margin: 0
      padding: 0
      padding-right: 1.2em
      width: 300px
      input
        font-size: 1.4em

  section.content

    .filters
      margin: 2em 2em 0
    .List
      margin-bottom: 2em
      .Header, .Cell

        &.id
          width: 80px
        &.listing
          flex: 1
          +ellipsize
        &.tickets, &.cost-items
          width: 70px
        &.actions
          width: 80px
          text-align: center

  .actionable
    cursor: pointer

  +filters


.Event
  +filters
  flex: 1
  > header
    display: flex
    align-items: center
    box-shadow: 0 1px 0 0 $gray-light
    height: 60px
    h1
      margin: 0
      padding: 0
      padding-left: 1.2em
      flex: 1

  main

    display: flex
    align-items: stretch
    section.content
      flex: 1
      .Summary
        margin: 20px

        .report-exports, .actions
          .alert
            display: inline-block
          ul
            list-style: none
            padding: 0
            li
              display: inline-block
              margin: 0 0.5em 0.5em 0
              svg
                margin-right: 0.5em
              a
                color: inherit

      .CostItems, .Tickets
        .Cell
          &.id
            width: 80px
          &.qr-code
            width: 100px
          &.user, &.rate
            flex: 1
          &.price, &.created-at
            width: 100px
            text-align: right
          &.actions
            width: 100px
            text-align: center

      .reset-checkins
        border: 1px solid $danger-bg
        border-radius: 4px
        padding: 0px 20px 20px
        margin-top: 40px
        select
          margin-bottom: 10px

      .seat-holds
        .seat-hold-button
          margin-right: 36px
          background-color: #3A66E5
          border-color: #3A66E5
          float: right
        .button-bar
          margin: 12px 0 0 36px
        #seats-chart
          margin: 36px 0
        .seat-select-copy
          font-weight: 600
          margin: 12px 0 0 36px
        .seat-label
          margin-left: 36px
          margin-right: 180px
      .no-seats
        margin: 12px 0 0 12px
        font-size: 16px

      div.tickets, div.cost-items
        display: table
        width: 100%
        font-size: 1.5rem
        margin-bottom: 1rem

        div.ticket-row, div.cost-item-row
          display: table-row
          width: 100%
          height: 70px
          &:nth-child(even)
            background: #fafafa
          & > div
            display: table-cell
            vertical-align: middle
            text-overflow: ellipsis
            white-space: nowrap
            // box-shadow: 1px 1px 0 0 $gray-light
            text-align: center
            &.buyer, &.email, &.rate
              text-align: left
          a
            color: $text-color
            &:hover
              color: $color-ticketmaster


      table
        td
          height: 70px
          font-size: 1.2em
          vertical-align: middle
          text-overflow: ellipsis
          white-space: nowrap
          a
            color: $text-color
            &:hover
              color: $color-ticketmaster
