@import 'base'
.RulesCell
  display: flex

  .rule
    display: flex
    margin-right: 30px

  .country-badge
    color: $danger-bg
    border: 1px solid $danger-bg
    border-radius: 2px
    padding: 4px 8px
    margin-left: 8px
    font-style: normal
    font-weight: 600
    font-size: 13px
