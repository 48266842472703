@import 'base'
.Changesets, .Changeset
  flex: 1
  > header
    display: flex
    align-items: center
    box-shadow: 0 1px 0 0 $gray-light
    height: 60px
    h1
      margin: 0
      padding: 0
      padding-left: 1.2em
      // display: inline-block
      flex: 1
    .search-form
      margin: 0
      padding: 0
      padding-right: 1.2em
      width: 300px
      input
        font-size: 1.4em

  +filters

  section.content
    .filters
      margin: 2em 2em 0
    .List
      margin-bottom: 2em
      .Cell
        &.target
          width: 250px
          +ellipsize
        &.info
          flex: 1
          +ellipsize
          table
            td
              width: 33%
        &.created
          width: 80px
          text-align: right

  main
    display: flex
    align-items: stretch
    section.content
      flex: 1

.Changeset
  section.content
    h2
      margin: 1em 10px

    dl
      padding: 0 10px
      dt
        color: $gray
        font-weight: 600
      dd
        font-size: 1.1em
        &.negative
          color: $color-red
          font-weight: bold
        & + dt
          margin-top: 1em
