@import 'base'
ul.HeroStats
  height: 100px
  box-shadow: 0 0 1px 1px #E2E4F5
  border-radius: 5px
  background: #FAFBFD
  list-style: none
  padding: 0 0 0 15px
  > li
    display: inline-block
    margin: 2em 5em 2em 2em
    .key
      display: block
      font-size: 1em
      color: rgba(0,0,0,0.4)
      margin-bottom: 5px
    .value
      display: block
      font-size: 2em
      color: rgba(0,0,0,0.8)
      margin-bottom: 5px
