@import 'base'
.ContactModal

  .contact-top
    margin-bottom: 5px


  .subject-input
    display: inline-block
    width: 100% !important
    height: 36px

    border-color: $gray-light
    margin-bottom: 18px

  textarea
    display: inline-block
    width: 100%
    height: 112px !important
    resize: none
    border-color: $gray-light
    border-radius: 4px
    padding: 6px 12px

  input:focus, textarea:focus
    outline: none
    box-sizing: border-box
    background-image: none
    border: 1px solid #DBE2ED
    border-radius: 4px
    box-shadow: 0 0 5px rgba(81, 203, 238, 1)
    border: 1px solid rgba(81, 203, 238, 1)
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075)
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s


