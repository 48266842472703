@import 'base'
.Withdrawals
  +filters
  flex: 1
  header
    display: flex
    align-items: center
    box-shadow: 0 1px 0 0 $gray-light
    height: 60px
    h2
      padding-left: 1.2em
    h1
      margin: 0
      padding: 0
      padding-left: 1.2em
      // display: inline-block
      flex: 1
    .search-form
      margin: 0
      padding: 0
      padding-right: 1.2em
      width: 300px
      input
        font-size: 1.4em

  section.content
    .filters
      margin: 2em 2em 0


.Withdrawal
  +filters
  flex: 1
  > header
    display: flex
    align-items: center
    box-shadow: 0 1px 0 0 $gray-light
    height: 60px
    h1
      margin: 0
      padding: 0
      padding-left: 1.2em
      flex: 1

  main

    display: flex
    align-items: stretch
    section.content
      flex: 1
      .Summary, .Tickets, .CostItems
        margin: 20px
