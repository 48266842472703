@import 'base'
.Countries
  flex: 1
  header
    display: flex
    align-items: center
    box-shadow: 0 1px 0 0 $gray-light
    height: 60px
    > span
      margin: 0
      padding: 0 1.2em
    h1
      margin: 0
      padding: 0 1.2em
      flex: 1
    .search-form
      margin: 0
      padding: 0
      padding-right: 1.2em
      width: 300px
      input
        font-size: 1.4em

  +filters

  section.content
    .filters
      margin: 2em 2em 0
    .List
      margin-bottom: 2em

      .Header, .Cell
        flex-shrink: 0
        &.code
          width: 80px
        &.currency_code
          width: 80px
        &.name
          flex: 1
        &.actions
          width: 80px
          text-align: center
