@import 'base'
aside.UserAside
  box-shadow: -1px 0 0 0 $gray-light
  width: 250px
  min-width: 250px
  min-height: 600px
  overflow: scroll
  max-height: calc(100vh - 60px)
  header.user-header
    // background-color: $gray
    // TODO: getting 429 too many requests on this image
    // background-image: url(http://www.twitterevolutions.com/bgs/blue-pattern-twitter-background.jpg)
    background-size: cover
    text-align: center
    color: white
    font-weight: 600
    > .overlay
      display: block
      padding: 30px 0
      background: linear-gradient(to bottom, rgba( black, 0 ), rgba( black, 0.1 ), rgba( black, 0.6 ), rgba( black, 0.4 ))
    h2, h3, a
      font-weight: inherit
      color: inherit
      text-shadow: 0 0 3px rgba( black, 0.3 )
    h3
      margin-top: 0
    img
      display: inline-block
      margin: 0
      border-radius: 50%
      box-shadow: 0 0 2px 2px white

    .social-row
      font-size: 1.2em
      a
        padding: 0 0.5em
        i.fa-twitter:hover
          color: #55acee
        i.fa-facebook:hover
          color: #3b5998
        i.fa-google:hover
          color: #dd4b39

  section + section
    box-shadow: inset 0 1px 1px $gray-light
  section[title]
    padding-top: 20px
    position: relative
    &:before
      content: attr(title)
      display: inline
      position: absolute
      top: 5px
      right: 10px
      text-align: right
      text-transform: capitalize
      font-size: 1.1em
      font-weight: bold
      color: rgba($gray, 0.33)

  dl
    padding: 0 10px
    dt
      color: $gray
      font-weight: 600
    dd
      font-size: 1.1em
      &.negative
        color: $color-red
        font-weight: bold
      & + dt
        margin-top: 1em
      &.mono
        font-family: monospace

      ul.stewards
        list-style: none
        margin: 0
        padding: 0
        button
          margin-left: 1em

  button.btn-xs
    font-weight: bold
    margin-bottom: 5px
