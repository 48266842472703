
//
// Base Styles
//

*, *:before, *:after
  box-sizing: border-box

body
  color: $text-color
  background: $body-bg
  font-size: $font-size-base
  font-family: $font-family-base
  line-height: $line-height-computed
  -webkit-font-smoothing: antialiased


//
// Headings
//

h1, h2, h3, h4, h5, h6
  color: $headings-color
  font-family: $headings-font-family
  font-weight: $headings-font-weight
  line-height: $headings-line-height
  -webkit-font-smoothing: antialiased

h1
  font-size: $font-size-h1
  // font-weight: 600

h2
  font-size: $font-size-h2

h3
  font-size: $font-size-h3

h4
  font-size: $font-size-h4

h5
  font-size: $font-size-h5

h6
  font-size: $font-size-h6


//
// Anchor
//

a
  cursor: pointer
  color: $link-color
  text-decoration: none

  &:link
    color: $link-color
    text-decoration: none

  &:visited
    color: $link-color
    text-decoration: none

  &:hover, &:focus
    color: $link-hover-color
    text-decoration: $link-hover-decoration

  &:active
    color: $link-color
    text-decoration: none



//
// Horizontal Rule
//

hr
  background: transparent
  border: none
  border-top: 1px solid $border-color


//
// Label
//

label
  color: $legend-color
  font-weight: 400


//
// Text elements
//

.subtle
  color: $text-color

.type-dark
  max-width: 500px
