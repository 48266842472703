@import 'base'
.UserCard
  .user-details
    display: inline-block
    vertical-align: middle
    line-height: 1.1em
    small
      display: block
      margin-top: 0.15em
      color: $gray

  .label-role
    vertical-align: bottom
    margin-left: 1em
    background-color: white
    border: 1px solid white
    border-color: $info-bg
    color: $info-bg
    &.Spammer
      border-color: $danger-bg
      color: $danger-bg

  small.email + *
    margin-top: 0.33em
