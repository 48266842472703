@import 'base'
.CostItemCard
  display: inline-block
  min-width: 300px
  max-width: 400px
  box-shadow: 0 0 2px 0 rgba(0,0,0,0.33)

  header.cost-item-card-header
    // background-color: $gray
    background-image: url(https://i.imgur.com/5YLEh3e.jpg)
    background-size: cover
    text-align: center
    color: white
    font-weight: 600
    > .overlay
      display: block
      padding: 30px 0
      background: linear-gradient(to bottom, rgba( black, 0 ), rgba( black, 0.1 ), rgba( black, 0.6 ), rgba( black, 0.4 ))
    h2, h3, a
      font-weight: inherit
      color: inherit
      text-shadow: 0 0 3px rgba( black, 0.3 )
    h3
      margin-top: 0
    img
      box-shadow: 0 0 2px 2px white
      // display: inline-block
      // margin: 0
      // border-radius: 50%
    .label
      font-size: 1em

  section + section
    box-shadow: inset 0 1px 1px $gray-light
  section[title]
    padding-top: 20px
    position: relative
    &:before
      content: attr(title)
      display: inline
      position: absolute
      top: 5px
      right: 10px
      text-align: right
      text-transform: capitalize
      font-size: 1.1em
      font-weight: bold
      color: rgba($gray, 0.33)

  dl
    padding: 0 10px
    dt
      color: $gray
      font-weight: 600
    dd
      font-size: 1.1em
      & + dt
        margin-top: 1em
