@import 'base'
.PaymentPlans
  +filters
  flex: 1
  header
    display: flex
    align-items: center
    box-shadow: 0 1px 0 0 $gray-light
    height: 60px
    h1
      margin: 0
      padding: 0
      padding-left: 1.2em
      // display: inline-block
      flex: 1

  section.content
    .filters
      margin: 2em 2em 0
    .List
      margin-bottom: 2em

      .Header, .Cell
        &.id, &.date
          width: 80px
        &.installments
          width: 80px
          text-align: right
          .StateLabel
            font-size: 0.85em
            padding: 0 0.5em
            margin-bottom: 0.5em
        &.actions
          width: 100px
          text-align: right

        &.user, &.listing
          flex: 1
          +ellipsize
        &.listing
          flex: 2

  .listing-details
    display: inline-block
    vertical-align: middle
    line-height: 1.1em
    small
      display: block
      margin-top: 0.15em
      color: $gray
      &.ip
        font-family: monospace
