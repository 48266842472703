@import 'base'
.Chargebacks
  flex: 1
  header
    display: flex
    align-items: center
    box-shadow: 0 1px 0 0 $gray-light
    height: 60px
    h1
      margin: 0
      padding: 0
      padding-left: 1.2em
      flex: 1
    .search-form
      margin: 0
      padding: 0
      padding-right: 1.2em
      width: 300px
      input
        font-size: 1.4em

  +filters
  section.content
    .filters
      margin: 2em 2em 0

    .Header, .Cell
      &.stripe-id
        width: 250px
      &.amount, &.fee, &.date-of-dispute, &.status
        width: 80px
      &.user
        flex: 1
        +ellipsize

    .Cell
      &.stripe-id
        font-family: monospace

  button:focus
    outline: 0
