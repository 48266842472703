@import 'base'
.button-bar
  display: flex
  height: 36px
  width: 275px

  .button
    cursor: pointer
    background-color: #FFF
    border-color: #E2E5E7
    color: $text-color
    border-top: 1px solid #E2E5E7
    border-bottom: 1px solid #E2E5E7
    border-left: 1px solid #E2E5E7
    font-size: 14px
    padding: 6px 12px
    width: 100%
    text-align: center
    align-self: center

  .button.active
    color: #3A66E5
    font-weight: 600
    border: 1px solid #3A66E5 !important

  .button:hover,
  .button:focus
    background-color: #F5F5F5

  .button:first-child
    border-top-left-radius: 6px
    border-bottom-left-radius: 6px
    border-left: 1px solid
    border-color: #E2E5E7
    clip-path: inset(-8px 0 -8px -8px)

  .button:last-child
    border-top-right-radius: 6px
    border-bottom-right-radius: 6px
    border-right: 1px solid
    border-color: #E2E5E7
    clip-path: inset(-8px -8px -8px 0)
