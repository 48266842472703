@import 'base'
.Translator
  text-transform: capitalize
  &.label
    &.yes
      background-color: $success-bg
    &.maybe
      background-color: $warning-bg
    &.no
      background-color: $danger-bg

