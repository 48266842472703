@import 'base'
.Tabs
  height: 60px
  box-shadow: inset 0 -1px 0 0 $gray-light, inset 0 1px 0 0 $gray-light
  background: $gray-lighter
  .tabs-navigation
    overflow-y: hidden
    white-space: nowrap
  .tabs-menu
    // list-style: none
    padding: 0 0 0 15px
    max-width: 1px // this is required for the overflow to not push out the container
    .tabs-menu-item
      display: inline-block
      height: 60px
      font-size: 1.1em
      a
        display: inline-block
        height: 60px
        padding: 0 20px
        line-height: 60px
        color: inherit
        text-decoration: none
        &:hover
          box-shadow: inset 0 -2px 0 0 rgba( $color-mw-blue, 0.25 )
        &.active
          box-shadow: inset 0 -2px 0 0 $color-mw-blue
