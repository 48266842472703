@import 'base'
.Attractions
  flex: 1
  header
    display: flex
    align-items: center
    box-shadow: 0 1px 0 0 $gray-light
    height: 60px
    h1
      margin: 0
      padding: 0
      padding-left: 1.2em
      flex: 1
    .search-form
      margin: 0
      padding: 0
      padding-right: 1.2em
      width: 300px
      input
        font-size: 1.4em

  button:focus
    outline: 0

  +filters

  section.content
    .filters
      margin: 2em 2em 0
    .List
      margin-bottom: 2em

      .Header, .Cell
        display: inline-block
        flex-shrink: 0
        &.id
          width: 180px
          +ellipsize
        &.name, &.discovery_id, &.legacy_id, &.edit
          width: 200px
          +ellipsize

  .NewAttractionButton
    margin-right: 32px

.NewAttraction
  .AttractionSearch
    label
      .filter-selector
        width: 100%
        display: inline-block

.AttractionSearch, .AttractionForm
  border: 1px $gray-light solid
  padding: 10px 15px
  margin-bottom: 10px
  border-radius: 4px
  
  label
    width: 100%
    display: block
    
  .attraction_bio
    min-height: 152px
    resize: none

  .attractionImage
    max-height: 315px
    width: 100%
    margin: 16px 0
    border-radius: 6px

.AddAttractionModal .modal-dialog
  width: 624px
